<template>
  <div>
    <b-form-input
        v-model="searchTerm"
        :placeholder="$t('label.searchPlaceholder')"
        type="text"
        class="d-inline-block mb-2"
        @input="globalSearch"
    />

    <b-overlay
        :show="loading"
        :opacity="0.1"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <!-- table -->
      <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
          @on-row-click="onRowClick"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
      >
        <div slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">
            {{ $t('table.list.empty') }}
          </div>
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >

          <!-- Column: Name -->
          <span
              v-if="props.column.field === 'source_url'"
              class="text-nowrap"
          >
            <span class="text-nowrap">
              {{ props.row.source_url }} ({{ props.row.code }})
              <b-button
                  size="sm"
                  variant="primary"
                  class="float-right"
              >
                <feather-icon
                    icon="ChevronRightIcon"
                />
              </b-button>
            <div class="font-small-1">{{ props.row.destination_url }}</div>
            <div class="font-small-1">{{ $formatters.formatLaravelTimestampToDateTime(props.row.updated_at) }}</div>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @change="handlePageChange"
              />
              <span class="text-nowrap ">{{ $t('table.list.pagination', {total: dataFromServer.total}) }}</span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="dataFromServer.total"
                  :per-page="dataFromServer.per_page"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination, BFormInput, BFormSelect, BButton, BOverlay,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormInput,
    BFormSelect,
    BButton,
    BOverlay,
  },
  data() {
    return {
      role: 'redirect',
      pageLength: 10,
      dir: false,
      pages: ['10', '20', '50', '100'],
      columns: [
        {
          label: this.$t('form.sourceUrl'),
          field: 'source_url',
          sortable: true,
        },
      ],
      rows: [],
      page: 1,
      dataFromServer: [],
      filterData: [],
      searchTerm: '',
      sortBy: {
        field: 'id',
        type: 'desc',
      },
      columnFilters: [],
      loading: false,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.loading = true
      const searchParams = {
        page: parseInt(this.page),
        limit: parseInt(this.pageLength),
        query: this.searchTerm,
        columnFilters: this.columnFilters,
        sort: this.sortBy,
      }

      this.$store.dispatch('fetchRedirects', searchParams).then(response => {
        this.rows = response.data.entities.data
        this.dataFromServer = response.data.entities
        this.page = this.dataFromServer.current_page
        this.pageLength = parseInt(this.dataFromServer.per_page)
      }).finally(() => {
        this.loading = false
      })
    },
    onRowClick(params) {
      this.$router.push({name: 'modify-redirect', params: {id: params.row.id}})
    },
    handleChangePage(page) {
      this.page = page
      this.initData()
    },
    handlePageChange(active) {
      this.pageLength = active
      this.page = 1
      this.handleChangePage()
    },
    globalSearch(params) {
      this.page = 1
      this.query = params.searchTerm
      this.initData()
    },
    onSortChange(params) {
      this.page = 1
      this.sortBy = params[0]
      this.initData()
    },
    onColumnFilter(params) {
      this.page = 1
      this.columnFilters = params.columnFilters
      this.initData()
    },
  },
}
</script>
